import React, { lazy, useEffect, useState } from 'react';
import {
    STATSIG_EXPERIMENTS,
    STATSIG_EXPERIMENTS_DEFAULTS
} from '../../../statsig/experiments';
import { ExperimentWrapper } from '../generic/experiment-wrapper';
// @ts-ignore
import { fetchCohortAttributes } from "../csm-chat-widget/user-entitlement-service";

interface SearchAIContainerProps {
    searchTerm: string;
}

const SearchAI = lazy(() =>
    // @ts-ignore
    import('./search-ai').then((module) => ({
        default: module.SearchAI
    }))
);

export const SearchAIContainer = ({ searchTerm }: SearchAIContainerProps) => {
    const [cohortAttributes, setCohortAttributes] = useState<any>(null);

    useEffect(() => {
        const fetchAttributes = async () => {
            const attributes = await fetchCohortAttributes();
            setCohortAttributes(attributes);
        };
        fetchAttributes();
    }, []);

    return (
        <ExperimentWrapper
            experimentName={STATSIG_EXPERIMENTS.SAC_SEARCH_AI}
            experimentParameter="ai_dialog_enabled"
            defaultValues={
                // @ts-ignore
                STATSIG_EXPERIMENTS_DEFAULTS[STATSIG_EXPERIMENTS.SAC_SEARCH_AI][
                    'ai_dialog_enabled'
                ]
            }
            customAttributes={{
                sacPath: window?.location?.pathname,
                hostname: window?.location?.hostname,
                isHipaaEntitlementPresent:
                    cohortAttributes?.isHipaaEntitlementPresent
            }}
            customErrorAttributes={{
                componentName: 'SearchAIContainer'
            }}
        >
            <SearchAI searchTerm={searchTerm} />
        </ExperimentWrapper>
    );
};
