import React, { useEffect, useState } from 'react';

import './footer-v2.less';
import { AtlasLink } from '../atlas-link';
import analytics from '../../utils/analytics';
import { AnalyticsMetadata } from '../../utils/analytics/types';
import { WAC_URL } from '../../utils/constants';
import { getLocalizedUrl } from '../../utils/get-localized-url';
import { translatableText } from '../../utils/translatable-text';

interface FooterLink {
    text: string;
    baseUrl: string;
    path: string;
}

interface LocalizedLink {
    text: string;
    href: string;
}

interface FooterV2BottomProps {
    analyticsMetadata: AnalyticsMetadata;
}

const copyRightYear = new Date().getFullYear();

const links: FooterLink[] = [
    {
        text: translatableText.accessibility.text,
        baseUrl: WAC_URL,
        path: '/accessibility'
    },
    {
        text: translatableText.yourPrivacyChoices.text,
        baseUrl: WAC_URL,
        path: '/legal/privacy-policy#additional-disclosures-for-ca-residents'
    },
    {
        text: translatableText.privacyPolicy.text,
        baseUrl: WAC_URL,
        path: '/legal/privacy-policy'
    },
    {
        text: translatableText.terms.text,
        baseUrl: WAC_URL,
        path: '/legal/cloud-terms-of-service'
    },
    {
        text: translatableText.impressum.text,
        baseUrl: WAC_URL,
        path: '/legal/impressum'
    },
    {
        text: translatableText.security.text,
        baseUrl: WAC_URL,
        path: '/trust/data-protection'
    }
];

export const FooterV2Bottom = ({ analyticsMetadata }: FooterV2BottomProps) => {
    const [localizedLinks, setLocalizedLinks] = useState<LocalizedLink[]>([]);

    useEffect(() => {
        const initializedLinks = links.map((link) => ({
            text: link.text,
            href: getLocalizedUrl(link.baseUrl, link.path)
        }));

        setLocalizedLinks(initializedLinks);
    }, []);

    return (
        <div className="footer-v2__bottom" data-testid="footer-v2-bottom">
            <div className="footer-v2__bottom__copyright">
                Copyright © {copyRightYear} Atlassian
            </div>
            <div className="footer-v2__bottom__links-and-language">
                {localizedLinks.map((link: LocalizedLink) => {
                    const isPrivacyChoicesLink =
                        link.text === translatableText.yourPrivacyChoices.text;

                    return (
                        <div
                            key={link.href}
                            className={`footer-v2__bottom__link__container${
                                isPrivacyChoicesLink
                                    ? ' atl-policy-link-container'
                                    : ''
                            }`}
                        >
                            <AtlasLink
                                text={link.text}
                                href={link.href}
                                className={`footer-v2__bottom__link${
                                    isPrivacyChoicesLink
                                        ? ' atl-policy-link atl-policy-link-text'
                                        : ''
                                }`}
                                data-testid="footer-v2-bottom-link"
                                onClick={() => {
                                    analytics.trackEvent('footer', {
                                        // @ts-ignore
                                        event: 'clicked',
                                        eventComponent: 'footer',
                                        actionSubjectId: 'link',
                                        category: 'footer link',
                                        action: 'clicked',
                                        label: 'footer bottom',
                                        footerLinkText: link.text,
                                        footerLink: link.href,
                                        ...analyticsMetadata
                                    });
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
