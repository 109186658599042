import {
    Block,
    BLOCKS,
    Document,
    helpers,
    Inline,
    MARKS,
    Text
} from '@contentful/rich-text-types';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import slugifyTitle from '../../../contentful-api/slugify-title';

export interface TransformMigratedArticleBodyInput {
    /** The rich text document to transform. */
    readonly document: Document;
}

export interface TransformMigratedArticleBodyOutput {
    /** The transformed rich text document. */
    readonly document: Document;

    /** The table of contents of the document. */
    readonly tableOfContents: readonly TableOfContentsSection[];
}

export interface TableOfContentsSection {
    /** The ID of the section. */
    readonly id: string;

    /** The title of the section. */
    readonly title: string;
}

const HEADER_MAP: ReadonlyMap<BLOCKS, number> = new Map([
    [BLOCKS.HEADING_2, 2],
    [BLOCKS.HEADING_3, 3],
    [BLOCKS.HEADING_4, 4],
    [BLOCKS.HEADING_5, 5],
    [BLOCKS.HEADING_6, 6]
]);

const REVERSE_HEADER_MAP: ReadonlyMap<number, BLOCKS> = new Map(
    [...HEADER_MAP].map(([key, value]) => [value, key])
);

/** The smallest header allowed. */
const SMALLEST_HEADER = 4;

/**
 * Indicates no header was found.
 * Since headers are numbered from 1-6, we use 7 to represent this.
 */
const NO_HEADER = 7;

/**
 * Transforms a migrated KB article as follows:
 * - Proportionately resize header nodes to ensure the biggest header is always {@link BLOCKS.HEADING_2}.
 * - Add an `id` property to the node `data`, so that the header ID can be used during rendering.
 */
export function transformMigratedArticleBody(
    input: TransformMigratedArticleBodyInput
): TransformMigratedArticleBodyOutput {
    const { document } = input;
    const biggestHeader = getBiggestHeader(document);

    // resize headers to ensure the biggest is always `BLOCKS.HEADING_2`
    if (biggestHeader !== NO_HEADER) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const resizeBy = biggestHeader - HEADER_MAP.get(BLOCKS.HEADING_2)!;
        const tableOfContents: TableOfContentsSection[] = [];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const transformedDocument = updateNode(
            document,
            biggestHeader,
            resizeBy,
            tableOfContents
        )!;

        return {
            document: transformedDocument,
            tableOfContents
        };
    }

    return { document, tableOfContents: [] };
}

function getBiggestHeader(node: Block, biggest: number = NO_HEADER): number {
    if (node.nodeType === BLOCKS.HEADING_1) {
        /*
         * This should be impossible during publishing, since Contentful validation ensures this is not present.
         * During preview, if the entry is created using the API, it may contain such nodes.
         */
        throw new Error(
            `${BLOCKS.HEADING_1} are not allowed, but the specified rich text document contains one or more.`
        );
    }

    for (const childNode of node.content) {
        if (helpers.isBlock(childNode)) {
            const childValue = getBiggestHeader(childNode, biggest);
            // the lower the value, the bigger the heading
            if (childValue < biggest) {
                biggest = childValue;
            }
        }
    }

    const nodeValue = HEADER_MAP.get(node.nodeType);
    return nodeValue && nodeValue < biggest ? nodeValue : biggest;
}

// eslint-disable-next-line complexity, max-params
function updateNode<T extends Block | Inline | Text>(
    node: T,
    biggestHeader: number,
    resizeBy: number,
    tableOfContents: TableOfContentsSection[],
    marks: MARKS[] = []
): T | null {
    // add marks to text (if applicable)
    if (helpers.isText(node)) {
        return marks.length > 0
            ? {
                  ...node,
                  marks: [...node.marks, ...marks.map((type) => ({ type }))]
              }
            : node;
    }

    const nodeChange: ProcessNodeOutput = helpers.isBlock(node)
        ? processNode(node, biggestHeader, resizeBy)
        : { action: 'update' };

    if (nodeChange.action === 'remove') {
        return null;
    }

    const { nodeType = null, section = null, mark = null } = nodeChange;

    // update table of contents
    if (section) {
        tableOfContents.push(section);
    }

    /*
     * Recursively process children nodes and if applicable:
     * - Replace node type (resize header or transform to paragraph)
     * - Add section id to data
     */
    return {
        ...node,
        nodeType: nodeType ?? node.nodeType,
        data: section
            ? {
                  ...node.data,
                  id: section.id
              }
            : node.data,
        content: node.content
            .map((n) =>
                updateNode(
                    n,
                    biggestHeader,
                    resizeBy,
                    tableOfContents,
                    mark ? [mark] : []
                )
            )
            .filter((n) => !!n) // remove nodes
    };
}

type ProcessNodeOutput =
    | {
          /** Indicates the node should be removed. */
          readonly action: 'remove';
      }
    | {
          /** Indicates the node should be updated. */
          readonly action: 'update';

          /** The node type to replace to (if any). */
          readonly nodeType?: BLOCKS;

          /** The section to add to the `data` property. */
          readonly section?: TableOfContentsSection;

          /** The marks to add (if any). */
          readonly mark?: MARKS;
      };

function processNode(
    node: Block,
    biggestHeader: number,
    resizeBy: number
): ProcessNodeOutput {
    const headerValue = HEADER_MAP.get(node.nodeType);

    if (!headerValue) {
        // node is not a header, nothing to change
        return { action: 'update' };
    }

    if (
        node.content.every(
            (x) => x.nodeType === 'text' && x.value.trim() === ''
        )
    ) {
        // node is empty header, remove it
        return { action: 'remove' };
    }

    // the lower the value, the bigger the heading
    const resizedHeaderValue = headerValue - resizeBy;

    if (resizedHeaderValue > SMALLEST_HEADER) {
        // headers smaller than allowed are transformed to bold paragraphs
        return {
            action: 'update',
            nodeType: BLOCKS.PARAGRAPH,
            mark: MARKS.BOLD
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const nodeType = REVERSE_HEADER_MAP.get(resizedHeaderValue)!;

    if (headerValue === biggestHeader) {
        // always resize the biggest header and update table of contents
        const title = documentToPlainTextString(node);
        return {
            action: 'update',
            nodeType,
            section: {
                id: slugifyTitle(title) ?? title,
                title
            }
        };
    }

    // headers bigger or equal than allowed should be resized
    return {
        action: 'update',
        nodeType
    };
}
